$(".toggle-password").click(function () {
    $(this).toggleClass("active");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

jQuery(document).ready(function ($) {

    $('.live-search-list li').each(function () {
        $(this).attr('data-search-term', $(this).text().toLowerCase());
    });

});
$(document).ready(function () {
    // $('.tabs').tabs();
    $('.carousel.carousel-slider').carousel({
        fullWidth: true,
        indicators: true
    });
    $("#main").click(function () {
        $('body').toggleClass('active-nav');
    });
    $(".chart-container").insertFusionCharts({
        type: "spline",
        width: "100%",
        height: "100%",
        dataFormat: "json",
        dataSource: {
            "chart": {
                //"caption": "Average Monthly Temperature in Texas",
                //"yaxisname": "Average Monthly Temperature",
                "anchorradius": "7",
                //"plottooltext": "Average temperature in $label is <b>$dataValue</b>",
                "showhovereffect": "1",
                "showvalues": "1",
                "numbersuffix": "",
                "theme": "candy",
                "anchorbgcolor": "#00e0c8",
                "palettecolors": "#00e0c8"
            },
            "data": [
                {
                    "label": "Sun",
                    "value": "10"
                },
                {
                    "label": "Mon",
                    "value": "35"
                },
                {
                    "label": "Tue",
                    "value": "25"
                },
                {
                    "label": "Wed",
                    "value": "45"
                },
                {
                    "label": "Thu",
                    "value": "55"
                },
                {
                    "label": "Fri",
                    "value": "65"
                },
                {
                    "label": "Sat",
                    "value": "5"
                }
            ]
        }
    });
});

function checkValue(element) {
    // check if the input has any value (if we've typed into it)
    if ($(element).val())
        $(element).addClass('has-value');
    else
        $(element).removeClass('has-value');
}

$(document).ready(function () {
    // Run on page load
    $('.material-design-form .form-control').each(function () {
        checkValue(this);
    })
    // Run on input exit
    $('.material-design-form .form-control').blur(function () {
        checkValue(this);
    });
});
