const syncStore = {};
self.addEventListener('install', function (event) {
  console.log('installing');
})
self.addEventListener('activate', function (event) {
  console.log('activating', event);
})
self.addEventListener('sync', event => {
  const { url, options, port, parse } = syncStore[event.tag] || {}
  delete syncStore[event.tag]
  event.waitUntil(fetch(url, options)
    .then(response => {
      const copy = response.clone()
      if (response.ok) {
        copy[parse]()
          .then(data => {
            port.postMessage(data)
          })
      } else {
        port.postMessage({ error: response.status })
      }
    })
    .catch(error => {
      port.postMessage({ error: error.message })
    })
  )
})
self.addEventListener('notificationclick', event => {
  console.log(event,event['notification']['data']['primaryKey'])
  // localStorage.setItem('clicked_notification',event['notification']['data']['primaryKey']);
  event.notification.close()
  event.waitUntil(
    self.clients.openWindow(`http://app.fantasticlife.ca/dashboard?id=${event['notification']['data']['primaryKey']}`)
  )
})
self.addEventListener('push', function (e) {
  console.log('push',e);
  if (e.data) {
    let notification = e.data.json()['data'];
    console.log('This push event has data: ', e.data.json());
    let title = notification['title'];
    var options = {
      body: notification['body'],
      icon: notification['icon'],
      vibrate: [100, 50, 100],
      data: {
        dateOfArrival: Date.now(),
        primaryKey: notification['notification_type']
      }
    };
    e.waitUntil(
      self.registration.showNotification(title, options)
    );
    console.log(notification, options);
  } else {
    console.log('This push event has no data.', e.data);
  }
  console.log('push', e);
});